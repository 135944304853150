import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { clearSnackbarAction } from "../../actions/uiActions";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";

const SuccessSnackBar = (props) => {
  const { clearSnackbar, successSnackbarOpen, successSnackbarMessage } = props;

  const handleClose = () => {
    clearSnackbar();
  };
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={successSnackbarOpen}
      autoHideDuration={4000}
      onClose={handleClose}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity="success"
      >
        {successSnackbarMessage}
      </MuiAlert>
    </Snackbar>
  );
};

const mapStateToProps = (state) => {
  return {
    successSnackbarMessage: state.uiState.successSnackbarMessage,
    successSnackbarOpen: state.uiState.successSnackbarOpen,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSnackbar: () => clearSnackbarAction(),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SuccessSnackBar);
