import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyBoT-n4D7iue54El7PT7FSvpzkNamCgOOQ",
  authDomain: "lala-e2744.firebaseapp.com",
  databaseURL: "https://lala-e2744.firebaseio.com",
  projectId: "lala-e2744",
  storageBucket: "lala-e2744.appspot.com",
  messagingSenderId: "572106391721",
  appId: "1:572106391721:web:e9bba5e820c2210e67e9b8",
  measurementId: "G-0GMRBEREWN",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
